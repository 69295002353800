import mixinComponent from "../../mixins/component";
import $ from "jquery";
import headlineTemplate from "./templates/headline";


export default function ArticleHeadlineComponent($el) {
	let self=this;
	this.initializeAsComponent($el);

	self.render.call(self, headlineTemplate);
	$(document).on("navbarVisibility", function(e) {
		self.setHeadlineVisibility(e);
	});
}

ArticleHeadlineComponent.prototype = {
	$articleHeadline: $("article-headline"),
	modifyHeadlineVisibility: true,
	events: {
		"navbarVisibility" : "setHeadlineVisibility"
	},
	// Initialize and render component
	render: function(template) {
		let contentType = window.digitalData.page.pageInfo.contentType;

		if (contentType !== undefined && contentType.length > 0) {
			let headlineData= {
				title: window.digitalData.page.attributes?window.digitalData.page.attributes.articleTitle:"",
				topic: this.evaluateTopic(),
				articleType: window.digitalData.page.pageInfo.contentType?window.digitalData.page.pageInfo.contentType:"",
				topicURL: (window.digitalData.page.pageInfo.pageCategoryName !== null && window.digitalData.page.pageInfo.pageCategoryName !== undefined)
					? "/topic/" + window.digitalData.page.pageInfo.pageCategoryName.toLowerCase().replace(/ /gi, "-").replace("&", "").replace(",","") 
					: this.evaluateTopic() ? `/${this.evaluateTopic().toLowerCase()}` : "javascript:void(0)",
				pageType: (document.getElementsByTagName("header").length > 0 && document.getElementsByTagName("header")[0].getAttribute("data-page")) ? document.getElementsByTagName("header")[0].getAttribute("data-page") : window.digitalData.page.pageInfo.pageCategoryName,
				seriesTitle: $(".series-nav--title").first().text(),
				series: "",
				bigIdea: ""
			}

			if ($(".article-content-flex2019").length > 0) {
				if (headlineData.pageType === "article") {
					let subPageType = $(".article-content-flex2019").attr("page-subtype");
					if (subPageType === "big-idea" || subPageType === "spotlight") {
						headlineData.pageType = subPageType;
					}
				}
			}

			if (headlineData.pageType !== null) {
				switch(headlineData.pageType.toLowerCase()) {
				case "utility":
					headlineData.topic = undefined;
					break;
				case "spotlight":
					headlineData.series = "Spotlight Series";
					break;
				case "big-idea":
					headlineData.bigIdea = "Big Idea Series";
					// Always render headline
					this.modifyHeadlineVisibility =  false;
					this.$articleHeadline.show();
					break;
				}
				this.$articleHeadline.html(template(headlineData));
			}
		}
	},

	setHeadlineVisibility: function(e) {
		if (this.modifyHeadlineVisibility) {
			if (e.isVisible) {
				this.$articleHeadline.hide();
			} else{
				this.$articleHeadline.show();
			}
		}
	},

	evaluateTopic: function() {
		// If podcast series page, change page topic to podcast otherwise return page category
		// Workaround required until podcast implements own page type
		return window.location.pathname.includes("/podcast-") ? "Podcast" : window.digitalData.page.pageInfo.pageCategoryName;
	}
}

mixinComponent(ArticleHeadlineComponent, "article-headline");
